import React, { useEffect, useState } from 'react'
import GameCards from '../components/webgames/gamecard';
import DataFetcher from '../components/fetch';
const WebGameListing = () => {
    const [GameData, setGameData] = useState([]);
    //const [recentlyPlayedListData, setrecentlyPlayedListData] = useState("");
    const [FeaturedGamesData, setFeaturedGamesData] = useState("");
    const [GameCardsData, setGameCardsData] = useState("");

    useEffect(() => {
        let isMounted = true;
        document.title = "Web Games - ChennaiGames";

        DataFetcher('get_webgames').then((apidata) => {
            if (isMounted) {
                setGameData(apidata);
            }
        });

        return () => {
            isMounted = false;
        };
        // Perform some side effect or data fetching
    }, []);
    useEffect(() => {
        console.log(GameData, 'fetch');
        if (GameData.length > 0) {
            //const storedGameIds = JSON.parse(localStorage.getItem('recentlyPlayedGames')) || [];
            //const recentlyPlayedList = storedGameIds.map(id => GameData.find(game => game.id === id));
            // const recentlyPlayedListData = recentlyPlayedList.map((key, index) => (
            //     <GameCards key={index} id={key.id} game_id={key.game_id} cardSize='col-lg-2 col-sm-3' imgUrl={'../' + key.thumbnail_image} vidUrl={'../' + key.thumbnail_video} alt={key.title} />
            // ));
            // setrecentlyPlayedListData(recentlyPlayedListData)
            const FeaturedGamesData = GameData.map((key, index) => {
                if (index <= 3) {
                    return (
                        <GameCards
                            key={index}
                            id={key.id}
                            game_id={key.game_id}
                            cardSize='col-lg-3 col-sm-6'
                            imgUrl={'../' + key.thumbnail_image}
                            vidUrl={'../' + key.thumbnail_video}
                            alt={key.title}
                        />
                    );
                } else {
                    return null;
                }
            });
            setFeaturedGamesData(FeaturedGamesData)
            const GameCardsData = GameData.map((key, index) => (
                <GameCards key={index} id={key.id} game_id={key.game_id} cardSize='col-lg-2 col-sm-3' imgUrl={'../' + key.thumbnail_image} vidUrl={'../' + key.thumbnail_video} alt={key.title} />
            ));
            setGameCardsData(GameCardsData)
        }
    }, [GameData]); // Logs GameData when it updates
    //FETCHING RECENTLY PLAYED DATA FROM LOCAL STORAGE
    // return false

    return (
        <>
            <div className='col-lg-12'>
                <div className='col-lg-12'>
                    <div className='row'>
                        <h5 style={{ fontSize: '25px', color: '#fff', fontWeight: 'bolder', padding: '10px 0px 10px 0px' }}>Featured Games:</h5>
                    </div>
                </div>
                <div className='row'>
                    {FeaturedGamesData}
                </div>

                <div className='col-lg-12'>
                    <div className='row'>
                        <h5 style={{ fontSize: '25px', color: '#fff', fontWeight: 'bolder', padding: '10px 0px 10px 0px' }}>New Games:</h5>
                    </div>
                </div>
                <div className='row'>
                    {GameCardsData}
                </div>

            </div>
        </>
    )
}

export default WebGameListing