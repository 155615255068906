const DataFetcher = async (api_name, data = []) => {
    try {
        let url = `https://chennaigames.com/api/${api_name}`
        const response = await fetch(url, { // Replace with your API endpoint
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        return result
    } catch (error) {
        console.log(error);
    } finally {
    }

}

export default DataFetcher;

