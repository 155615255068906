import React from 'react';

export default function HeroBanner() {
    return (
		<section class="page-banner" style={{backgroundImage:"url(images/background/title-bg-3.jpg)"}}>
        <div class="auto-container">
            <div class="inner-container clearfix">
                <ul class="bread-crumb clearfix">
                   
                </ul>
                <h1>Blog</h1>
            </div>
        </div>
    </section>
    )
}