import React from 'react';
import Marquee from "react-fast-marquee";
import MrRacerDownloadLinks from "../../data/ourgames.json";

export default function StuntMania() {
    return (
        <>
            <div className='col-12'>
                <div className='col-12'>

                    <div className="sec-title centered" style={{ paddingTop: '150px' }}>
                        <img src='./images/stuntmania/banner.png' alt='' style={{ maxWidth: '500px' }} />
                    </div>
                    
                    <div className="sec-title centered">
                       <h2>-) Coming Soon (-</h2>
                    </div>
                </div>
                <br/>
               
            </div>
        </>
    )
}