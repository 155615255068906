/* global $ */
import React, { useEffect, useRef } from 'react';
import Social from './social';
import { Link } from 'react-router-dom'
// import CustomLink from './custom_link';

export default function Header() {

    const isMounted = useRef(true);
    function handleOrientationChange() {
        const orientation = window.orientation;
        if (orientation === 0 || orientation === 180) {
            //alert('Portrait mode');
            // $('#mCSB_scrollbar_vertical').css('display','none');
            $("#mCSB_1").css('max-height', '800px');
        } else if (orientation === 90 || orientation === -90) {
            //alert('Landscape mode');
            // $('#mCSB_scrollbar_vertical').css('display','none');
            $("#mCSB_1").css('max-height', '800px');
        }
    }

    window.addEventListener('resize', handleOrientationChange);
    function handlePreloader() {
        // alert('0')
        $('body').removeClass('page-loaded');
        $('.preloader').css('display', 'block')
        if ($('.preloader').length) {
            // alert(1)
            // $('body').addClass('page-loaded');
            $('.preloader').delay(1000).fadeOut(300);
        }
    }

    useEffect(() => {


        isMounted.current = true;

        const handleScroll = () => {
            if (isMounted.current) {
                const windowpos = window.scrollY;
                const siteHeader = document.querySelector('.main-header');
                const scrollLink = document.querySelector('.scroll-to-top');
                const stickyHeader = document.querySelector('.main-header .sticky-header');

                if (siteHeader) {
                    if (windowpos > 100) {
                        siteHeader.classList.add('fixed-header');
                        stickyHeader.classList.add('animated', 'slideInDown');
                        scrollLink.style.display = 'block';
                    } else {
                        siteHeader.classList.remove('fixed-header');
                        stickyHeader.classList.remove('animated', 'slideInDown');
                        scrollLink.style.display = 'none';
                    }
                }
            }
        };

        const initMobileMenu = () => {
            if (document.querySelector('.mobile-menu')) {
                $('.mobile-menu .menu-box').mCustomScrollbar();

                const mobileMenuContent = document.querySelector('.main-header .nav-outer .main-menu').innerHTML;
                document.querySelector('.mobile-menu .menu-box .menu-outer').innerHTML = mobileMenuContent;
                document.querySelector('.sticky-header .main-menu').innerHTML = mobileMenuContent;

                document.querySelectorAll('.mobile-menu li.dropdown .dropdown-btn').forEach(btn => {
                    btn.addEventListener('click', () => {
                        btn.classList.toggle('open');
                        const ul = btn.previousElementSibling;
                        if (ul) {
                            if (ul.style.display === 'block') {
                                ul.style.display = 'none';
                            } else {
                                ul.style.display = 'block';
                            }
                        }
                    });
                });

                document.querySelector('.mobile-nav-toggler').addEventListener('click', () => {
                    document.body.classList.add('mobile-menu-visible');
                });

                document.querySelectorAll('.mobile-menu .menu-backdrop, .mobile-menu .close-btn').forEach(el => {
                    el.addEventListener('click', () => {
                        document.body.classList.remove('mobile-menu-visible');
                    });
                });
            }
        };

        window.addEventListener('scroll', handleScroll);
        initMobileMenu();

        return () => {
            isMounted.current = false;
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <header className="main-header">
            <div className="header-top">
                <div className="auto-container clearfix">
                    <div className="top-left clearfix">
                        <ul className="info-list">
                            {/* <li>Ignite Your Passion: Where Gaming Is More Than a Hobby! 🎮</li> */}
                        </ul>
                    </div>

                    <div className="top-right">
                        <ul className="social-icons">
                            <Social />
                        </ul>
                    </div>
                </div>
            </div>
            <div className="preloader" id='preload'>
                <div className="icon"></div>
            </div>

            <div className="header-upper">
                <div className="inner-container">
                    <div className="auto-container clearfix">
                        <div className="logo-outer">
                            <div className="logo"><Link to="/home"><img src="../images/logo.avif" alt=""
                                title="Chennaigames Studio" /></Link></div>
                        </div>
                        <div className="nav-outer clearfix">
                            <div className="mobile-nav-toggler"><span className="icon flaticon-menu-1"></span></div>
                            <nav className="main-menu navbar-expand-md navbar-light">
                                <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                    <ul className="navigation clearfix pull-left">
                                        <li><Link to="/home" onClick={() => { handlePreloader() }}>Home</Link></li>
                                        <li><Link to="/about-us" onClick={() => { handlePreloader() }}>about us</Link></li>
                                        <li><Link to="/gallery" onClick={() => { handlePreloader() }}>Gallery</Link></li>
                                        <li><Link to="/ourgames" onClick={() => { handlePreloader() }}>Our Games</Link></li>
                                    </ul>

                                    <ul className="navigation pull-right clearfix">

                                        {/* <li><Link to="/blog">Blog</Link></li> */}
                                        <li><Link to="/careers" onClick={() => { handlePreloader() }}>Careers</Link></li>
                                        <li><Link to="/contact-us" onClick={() => { handlePreloader() }}>Contact Us</Link></li>
                                        <li><Link to="/games" onClick={() => { handlePreloader() }}><img src='../images/joystick.gif' alt='' style={{ width: '20px' }} />&nbsp;&nbsp;Web Games</Link></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>



            <div className="sticky-header">
                <div className="auto-container clearfix">

                    <div className="logo pull-left">
                        <a href="/home" title=""><img src="images/logo.avif" alt="" title="" /></a>
                    </div>

                    <div className="pull-right">

                        <nav className="main-menu clearfix">

                        </nav>
                    </div>
                </div>
            </div>


            <div className="mobile-menu">
                <div className="menu-backdrop"></div>
                <div className="close-btn"><span className="icon flaticon-close"></span></div>

                <nav className="menu-box">
                    <div className="nav-logo"><a href="/"><img src="images/logo.avif" alt="" title="" /></a></div>
                    <div className="menu-outer">

                        <div className="social-links">
                            <ul className="clearfix">
                                <Social />
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}