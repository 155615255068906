import React from 'react';

export default function HeroBanner() {
    return (
        <section className="page-banner" style={{backgroundImage:"url(../images/background/title-bg-1.avif)"}}>
			<div className="auto-container">
				<div className="inner-container clearfix">
					<ul className="bread-crumb clearfix">
						
					</ul>
					<h1>About Us</h1>
				</div>
			</div>
		</section>
    )
}