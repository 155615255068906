import React from 'react'
import HeroBanner from '../components/blog/herobanner'
import BlogList from '../components/blog/blog_list'
const Blog = () => {
    return (
       <>
        <HeroBanner/>
        <BlogList/>
       </>
    )
}

export default Blog